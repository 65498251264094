<template>
    <div>
      <form v-if="service.id" @submit.prevent="handleSubmit">
        <b-form-group>
          <!-- Title & Sub_Title -->
          <div class="mb-3">
            <b-row>
              <b-col>
                <div>
                  <label for="title" class="font-weight-bolder">Title :</label>
                  <b-form-input
                    v-model="service.title"
                    id="title"
                    placeholder="Enter Service Title"
                  >
                  </b-form-input>
                  <p v-if="errors.title" class="text-danger">{{ errors.title[0] }}</p>
                </div>
              </b-col>
  
              <b-col>
                <div>
                  <label for="subTitle" class="font-weight-bolder"
                    >Sub Title :</label
                  >
                  <b-form-input
                    v-model="service.sub_title"
                    id="subTitle"
                    placeholder="Enter Service Sub Title"
                  >
                  </b-form-input>
                  <p v-if="errors.sub_title" class="text-danger">{{ errors.sub_title[0] }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
  
          <!-- Categories  @change="changeArrToString"  v-model="service" -->
          <div class="mb-3">
            <label for="categories" class="font-weight-bolder"
              >Categories :</label
            >
            <b-form-checkbox-group
              class="d-flex justify-content-between align-items-center"
            >
              <b-form-checkbox
                v-model="service.category_id"
                v-for="c in categories"
                :key="c.id"
                :value="c.id"
                class="h4"
                size="lg"
                >{{ c.name }}</b-form-checkbox
              >
            </b-form-checkbox-group>
            <p v-if="errors.category_id" class="text-danger">{{ errors.category_id[0] }}</p>
          </div>
  
          <!-- Description -->
          <div class="mb-3">
            <label for="des" class="font-weight-bolder">Description :</label>
            <vue-editor :editorToolbar="quillBar" v-model="service.description"></vue-editor>
            <p v-if="errors.description" class="text-danger">{{ errors.description[0] }}</p>
          </div>
  
          <!-- Contact Info -->
          <div class="mb-3">
            <label for="contact-info" class="font-weight-bolder"
              >Contact Info :</label
            >
            <b-form-input
              v-model="service.contact_info"
              id="contact-info"
              placeholder="Enter Contact Info"
            >
            </b-form-input>
            <p v-if="errors.contact_info" class="text-danger">{{ errors.contact_info[0] }}</p>
          </div>
  
          <!-- Email & Phone -->
          <b-row class="mb-3">
            <b-col>
              <div>
                <label for="email" class="font-weight-bolder">Email :</label>
                <b-form-input
                  v-model="service.email"
                  id="email"
                  type="email"
                  placeholder="example@gmail.com"
                >
                </b-form-input>
                <p v-if="errors.email" class="text-danger">{{ errors.email[0] }}</p>
              </div>
            </b-col>
  
            <b-col>
              <div>
                <label for="phno" class="font-weight-bolder">Phone :</label>
                <b-form-input
                  v-model="service.phone"
                  id="phno"
                  placeholder="Phone Number"
                >
                </b-form-input>
                <p v-if="errors.phone" class="text-danger">{{ errors.phone[0] }}</p>
              </div>
            </b-col>
          </b-row>
  
          <!-- Latitude & Longitude -->
          <div class="mb-3">
            <b-row>
              <b-col>
                <div>
                  <label for="lat" class="font-weight-bolder">Latitude :</label>
                  <b-form-input
                    v-model="service.lat"
                    id="lat"
                    placeholder="example - 16.768148"
                  >
                  </b-form-input>
                  <p v-if="errors.lat" class="text-danger">{{ errors.lat[0] }}</p>
                </div>
              </b-col>
  
              <b-col>
                <div>
                  <label for="lon" class="font-weight-bolder">Longitude :</label>
                  <b-form-input
                    v-model="service.lng"
                    id="lng"
                    placeholder="example - 96.174930"
                  >
                  </b-form-input>
                  <p v-if="errors.lon" class="text-danger">{{ errors.lon[0] }}</p>
                </div>
              </b-col>
            </b-row>
          </div>
  
          <!-- Booking_status & Publish_status -->
          <b-row class="mb-3">
            <b-col>
              <div>
                <label for="status" class="font-weight-bolder"
                  >Booking Mode :</label
                >
                <b-form-checkbox
                  v-model="booking_mode"
                  @change="toggle(booking_mode)"
                  name="check-booking-button"
                  switch
                >
                </b-form-checkbox>
              </div>
            </b-col>
  
            <b-col>
              <div>
                <label for="status" class="font-weight-bolder">Publish :</label>
                <b-form-checkbox
                  v-model="publish"
                  @change="toggle(publish)"
                  name="check-publish-button"
                  switch
                >
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
  
          <!-- States -->
          <div class="mb-3">
            <label for="states" class="font-weight-bolder">States :</label>
            <b-form-select v-model="service.state_id">
              <b-form-select-option disabled value=""
                >Please Select a state</b-form-select-option
              >
              <b-form-select-option
                v-for="s in states"
                :key="s.id"
                :value="s.id"
                >{{ s.name }}</b-form-select-option
              >
            </b-form-select>
            <p v-if="errors.state_id" class="text-danger">{{ errors.state_id[0] }}</p>
          </div>
  
          <!-- Opening_time & Closing_time -->
          <b-row class="mb-3">
            <b-col>
              <div>
                <label for="open" class="font-weight-bolder"
                  >Opening Time :</label
                >
                <b-form-timepicker
                  locale="en"
                  v-model="service.opening_time"
                  @context="openContext"
                ></b-form-timepicker>
                <p v-if="errors.opening_time" class="text-danger">{{ errors.opening_time[0] }}</p>
              </div>
            </b-col>
  
            <b-col>
              <div>
                <label for="close" class="font-weight-bolder"
                  >Closing Time :</label
                >
                <b-form-timepicker
                  locale="en"
                  @context="closeContext"
                  v-model="service.closing_time"
                ></b-form-timepicker>
                <p v-if="errors.closing_time" class="text-danger">{{ errors.closing_time[0] }}</p>
              </div>
            </b-col>
          </b-row>
  
          <!-- Photos -->
          <div class="mb-4">
            <label for="contact-info" class="font-weight-bolder">Photos :</label>
            <div v-if="service.id">
              <MyPhotoUpload :images="previewImages" @getPhotoId="uploadPhoto" @delete="removeImage" />
            </div>
          </div>
        </b-form-group>
  
        <div class="d-flex flex-column flex-md-row">
          <div class="w-100 text-center mb-2 mb-md-0">
            <b-button
              type="button"
              variant="outline-primary"
              @click="() => router.go(-1)"
              class="col-md-10 col-lg-8"
            >
              <span>Back</span>
            </b-button>
          </div>
  
          <div class="w-100 text-center">
            <b-button variant="primary" :disabled="loading" class="col-md-10 col-lg-8" type="submit">
              <b-spinner v-if="loading"></b-spinner>  
              <span v-else>Submit</span>
            </b-button>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center" v-else>
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "@vue/composition-api";
  import {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormTimepicker,
    BFormDatepicker,
    BSpinner
  } from "bootstrap-vue";
  import { VueEditor } from "vue2-quill-editor";
  import MyPhotoUpload from "../../components/MyPhotoUpload.vue";
  import store from "@/store";
  import router from "@/router";
  import { useRouter } from '@core/utils/utils'
  export default {
    components: {
      BSpinner,
      BCard,
      BButton,
      BRow,
      BCol,
      BFormInput,
      BFormGroup,
      BDropdown,
      BDropdownItem,
      BModal,
      BFormSelect,
      BFormSelectOption,
      BFormTextarea,
      BFormCheckbox,
      BFormCheckboxGroup,
      BFormTimepicker,
      BFormDatepicker,
      // MapLocationSelector,
      VueEditor,
      MyPhotoUpload,
    },
    setup() {
      const {route , router} = useRouter();
      const categories = ref([]);
      const states = ref([]);
      const loading = ref(false);
      const service = ref({
        title: "",
        category_id: [],
        // user_id: "",
        sub_title: "",
        description: "",
        contact_info: "",
        phone: "",
        email: "",
        lat: "",
        lon: "",
        booking_mode: 1,
        status: "active",
        lng: "",
        state_id: "",
        opening_time: "",
        closing_time: "",
        photos: [],
      });
      const booking_mode = ref(true);
      const publish = ref(true);
      const mulImgs = ref([]);
      const errors = ref({});
      const previewImages = ref([]);
      const quillBar = [['link', 'image' , 'video'],['bold', 'italic', 'underline'],[{ 'list': 'ordered'}, { 'list': 'bullet' }],['code-block'] , ['blockquote'],[{ 'header': 1 }, { 'header': 2 }],[{ 'script': 'sub'}, { 'script': 'super' }],[{ 'size': ['small', false, 'large', 'huge'] }],[{ 'header': [1, 2, 3, 4, 5, 6, false] }],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }],[{ 'align': [] }],];

      const getService = () => {
        store.dispatch("app-mypages/fetchDetails", {
          path: "services",
          id: route.value.params.id,
        })
        .then((response) => {
          service.value = response.data.data;
          let photos = service.value.photos;
          previewImages.value = service.value.photos;
          service.value.photos = [];
          photos.forEach((photo) => {
            service.value.photos.push(photo.id)
            mulImgs.value.push(photo.id)
          })
          console.log(service.value.photos , 'photos')
        });
      }
      getService();
  
      const getCategories = () => {
        store
          .dispatch("app-mypages/fetchDatas", "categories").then((res) => {
            categories.value = res.data.data
          }).catch((res) => {
            console.log(res)
          })
      }
      getCategories();
  
      const getStates = () => {
        store.dispatch("app-mypages/fetchDatas", "states").then((response) => {
          states.value = response.data.data;
        }).catch((res) => {
          console.log(res);
        })
      }
      getStates();
  
      const handleSubmit = () => {
        // service.value.lng = service.value.lon;
        loading.value = true;
        store
        .dispatch("app-mypages/updateData", {
          path: "services",
          payload: service.value,
        })
          .then(() => {
        loading.value = false;
            router.push('/my-pages/services');
          }).catch((res) => {
        loading.value = false;
            errors.value = res.response?.data?.errors;
          });
      }
  
      const toggle = (c = booking_mode.value, p = publish.value) => {
        c ? (service.value.booking_mode = 1) : (service.value.booking_mode = 0);
        p
          ? (service.value.status = "active")
          : (service.value.status = "unactive");
      };
  
      const uploadPhoto = (photoIds) => {
        mulImgs.value = [...mulImgs.value, photoIds];
        service.value.photos = mulImgs.value;
      };

      const removeImage = (path , index) => {
        previewImages.value = previewImages.value.filter(img => path != img.name)
        mulImgs.value = mulImgs.value.filter((img , i) => i != index)
        service.value.photos = mulImgs.value;
      }
  
      const openContext = (ctx) => {
        service.value.opening_time = ctx.value.slice(0, 5);
      };
  
      const closeContext = (ctx) => {
        service.value.closing_time = ctx.value.slice(0, 5);
      };
  
      return {
        categories , states , service , errors , booking_mode , publish , mulImgs , toggle , uploadPhoto , openContext , closeContext , handleSubmit , previewImages , removeImage, quillBar,loading
      }
  
    },
  
  };
  </script>
  
  <style scoped>
  </style>